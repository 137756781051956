import { useEffect, useState } from 'react'

import Layout from 'containers/Layout'
import moment from 'moment'
import { get, truncate } from 'lodash'
import apiGet from 'lib/network/apiGet'

import { useHistory, useParams } from 'react-router-dom'

import Blockies from 'react-blockies'
import Selection from 'components/molecules/Selection'

import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import Button from 'components/atoms/Button'
import { startEndTruncate, accessibleOnClick } from 'utils'

import { Tooltip } from 'antd'
import { IoCopyOutline, IoSearch } from 'react-icons/io5'
import notify from 'components/atoms/Notify'
import useModal from 'hooks/useModal'

import ImportUsers from 'pages/company/modals/ImportUsers'
import CarAuth from './modals/CarAuth'

const Cars = () => {
  const history = useHistory()
  const [filter, setFilter] = useState({ workspace: -1 })
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [cars, setCars] = useState([])
  const [companyNames, setCompanyNames] = useState([])
  const [search, setSearch] = useState('')

  const [users, setUsers] = useState()

  const { id } = useParams()

  useEffect(() => {
    fetchCars()
  }, [filter, search])

  const fetchCars = async (page = 1, company = null) => {
    if (page === 1) {
      setLoading(true)
    }
    const params = {}
    params.pageNumber = page
    params.pageSize = 20
    params.query = search || undefined
    if (filter.company || company) params.companyId = company || filter.company

    const response = await apiGet('cars', params)

    setCars(page === 1 ? response.data.rentalCars : [...cars, ...response.data.rentalCars])
    setPagination(response?.data?.pagination || [])

    setLoading(false)
  }

  const copyText = text => {
    navigator.clipboard.writeText(text).then(() => {
      notify.success('Copied to clipboard', truncate(text, { length: 35 }))
    })
  }
  const handleAddCarSuccess = carId => {
    fetchCars()
    closeModal()
  }

  const [SmsAuthModal, openSmsModel, closeModal] = useModal({
    content: <CarAuth onSuccess={handleAddCarSuccess} />,
    title: 'Add Car',
    dimensions: {
      height: '650px',
      width: '850px',
    },
  })

  const [ImportUserModal, openImportUserModal] = useModal({
    content: <ImportUsers refetch={fetchCars} />,
    title: 'Import Car',
    dimensions: {
      height: '320px',
    },
  })

  useEffect(() => {
    fetchCompanies()
  }, [])

  const fetchCompanies = async () => {
    const response = await apiGet('companies')
    if (response?.data) {
      const companies = response.data.company.map(company => ({
        id: company._id,
        name: company.name,
      }))
      setCompanyNames(companies)
    } else {
      setCompanyNames([])
    }
  }

  const handleCompanyTypeChange = selectedOption => {
    setFilter(prevFilter => ({
      ...prevFilter,
      company: prevFilter.company === selectedOption?.id ? null : selectedOption?.id,
    }))
  }
  const columns = [
    {
      name: 'Name',
      width: '10%',
      key: 'name',
      sortable: true,
      rendered: item => {
        return (
          <div className="flex items-center cursor-pointer" {...accessibleOnClick(() => (window.location.href = `/car/edit/${item._id}/car-profile`))}>
            <div className="flex-shrink-0 h-10 w-10 relative">
              {item?.carImages?.[0]?.thumbnailUrl ? (
                <img className="h-10 w-10 rounded-full" src={item?.carImages?.[0]?.thumbnailUrl} alt="" />
              ) : (
                <Blockies className="rounded-full" seed={item.walletAddress || item.email || ''} size={6.5} scale={6} />
              )}
            </div>
            <div className="ml-4">
              <div className="text-sm text-normal text-gray-900">
                {item?.car?.make} {item?.car?.model} {item?.modelYear}
              </div>
              <div
                // eslint-disable-next-line
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
                className="text-[13px] text-gray-500 flex"
              >
                {item.walletAddress ? (
                  <span className="flex items-center">
                    <Tooltip title={`${item.walletAddress}`}>
                      <span
                        // eslint-disable-next-line
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          copyText(item.walletAddress)
                        }}
                        className="text-[13px] hover:text-indigo-500 cursor-pointer"
                      >
                        {startEndTruncate(item?.walletAddress, 6)}
                      </span>
                    </Tooltip>
                    <button id="disable-export" className="hover:text-indigo-500" onClick={() => copyText(item?.walletAddress)}>
                      <IoCopyOutline className="ml-2" />
                    </button>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      width: '10%',
      name: 'Status',
      key: 'status',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item.status}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Segment',
      key: 'marketSegment',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item?.car?.marketSegment}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Fuel Policy',
      key: 'fuelPolicy',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item.fuelPolicy}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Insurance',
      key: 'insurance',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item.insurance}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '5%',
      name: 'Company Name',
      key: 'company',
      sortable: true,
      rendered: item => {
        if (item.company && item.company.length > 0) {
          return <span className=" text-[13px]">{item.company[0].name}</span>
        }
      },
    },

    {
      width: '5%',
      name: 'Created',
      key: 'created',
      sortable: true,
      rendered: item => {
        const createdTime = get(item, 'createdAt', '')
        const createdAt = createdTime ? moment(createdTime).fromNow() : 'Registered'
        return createdAt
      },
    },
  ]

  const filterColumns = columnsData => {
    return columnsData.filter(item => {
      if (id && item.key === 'workspace') return false

      if (id && item.key === 'lastLogin') return true
      if (!id && item.key === 'lastLogin') return false

      return true
    })
  }

  return (
    <Layout
      showLogo={!!id}
      breadcrumbs={[{ name: 'Cars', pathname: '/cars' }]}
      headerRight={
        <div className="flex items-center w-full justify-end sm:mt-0 sm:ml-4">
          <Selection
            value={filter.company}
            onChange={e => {
              setCars([])
              handleCompanyTypeChange(e)
            }}
            className="min-w-[200px] mr-4"
            list={companyNames}
            title="Company Type Filter"
          />

          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
              <IoSearch className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
            </div>
            <input
              onChange={e => setSearch(e.target.value)}
              value={search}
              type="text"
              name="search"
              id="search"
              className="block  pl-9 shadow-none sm:text-sm bg-gray-100 border-0 focus:outline-none rounded-sm"
              placeholder="Search options"
            />
          </div>
          <Button onClick={openImportUserModal}>Import Cars</Button>
          <Button onClick={openSmsModel}>Add Car</Button>
        </div>
      }
      title="Cars"
    >
      <div className="flex flex-col">
        <div className="flex-1 flex items-stretch">
          <main className="flex-1">
            <div className="align-middle inline-block min-w-full">
              <div className="min-w-full">
                {!loading ? (
                  <>
                    {cars?.length === 0 ? (
                      <EmptyState v2 description="No Cars found" />
                    ) : (
                      <Table
                        hasMore={pagination?.currentPage !== pagination?.totalPages}
                        fetchData={() => fetchCars(pagination?.currentPage + 1)}
                        sortBy={filter.sortBy}
                        sortOrder={filter.sortOrder}
                        handleSort={(sortBy, sortOrder) => {
                          setUsers([])
                        }}
                        data={cars}
                        columns={filterColumns(columns)}
                      />
                    )}
                  </>
                ) : (
                  <div className="w-full h-[200px] border-0 flex justify-center items-center">
                    <LoadingIcon />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
      <SmsAuthModal />
      <ImportUserModal />
    </Layout>
  )
}

export default Cars
