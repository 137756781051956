import { lazy } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AuthRoute from 'containers/AuthRoute'
import AuthContainer from 'containers/AuthContainer'
import NotFound from 'pages/common/NotFound'
import Users from 'pages/users/Users'
import Companies from 'pages/company/Companies'
import Cars from 'pages/car/Cars'
import AddEditUser from 'pages/users/AddEditUser'
import AddEditCompany from 'pages/company/AddEditCompany'
import AddEditCar from 'pages/car/AddEditCar'

import ForgotPassword from 'pages/auth/ForgotPassword'
import Discounts from 'pages/discount/Discounts'
const DashboardPage = lazy(() => import('pages/dashboard'))
const LoginPage = lazy(() => import('pages/auth/Login'))

const LayoutRouter = ({ loading, isUser, user = {}, openChangePasswordModal }) => {
  const routes = [
    { path: '/login', exact: true, isPublic: true, isUser, component: LoginPage, showSideBar: false },
    { path: '/forgot', exact: true, isPublic: true, isUser, component: <ForgotPassword />, showSideBar: false },
    { path: '/dashboard', isUser, component: <DashboardPage /> },
    { path: '/users/add', isUser, component: <AddEditUser /> },
    { path: '/users/edit/:id', isUser, component: <AddEditUser /> },
    { path: '/users/edit/:id/admins', isUser, component: <Users />, key: 'id' },
    { path: '/users/:id', isUser, component: <Users /> },
    { path: '/users', isUser, component: <Users /> },
    { path: '/companies', isUser, component: <Companies /> },

    { path: '/add/company', isUser, component: <AddEditCompany /> },
    { path: '/company/edit/:id', isUser, component: <AddEditCompany /> },
    { path: '/cars', isUser, component: <Cars /> },
    { path: '/car/edit/:id', isUser, component: <AddEditCar /> },

    { path: '/discounts', isUser, component: <Discounts /> },
  ]

  return (
    <Router>
      <AuthContainer loading={loading}>
        {!loading && user ? (
          <>
            <Switch>
              <AuthRoute isUser={isUser} component={<LoginPage />} showSideBar={false} isPublic exact path="/login" />
              <AuthRoute isUser={isUser} component={<ForgotPassword />} showSideBar={false} isPublic exact path="/forgot" />

              {routes?.map((route, index) => {
                return <AuthRoute key={route.key || index} {...route} />
              })}

              {!isUser && <AuthRoute component={<Redirect to="/login" />} isUser={isUser} path="/" />}

              {isUser && <AuthRoute component={<Redirect to="/users" />} isUser={isUser} path="/" />}

              <Route component={NotFound} />
            </Switch>
          </>
        ) : (
          <Switch>
            <AuthRoute isUser={isUser} component={<LoginPage />} showSideBar={false} isPublic exact path="/login" />
            <AuthRoute component={<Redirect to="/login" />} isUser={isUser} path="/" />
            <AuthRoute isUser={isUser} component={<ForgotPassword />} showSideBar={false} isPublic exact path="/forgot" />
          </Switch>
        )}
      </AuthContainer>
    </Router>
  )
}

export default LayoutRouter
