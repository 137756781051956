import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoadingIcon from 'components/atoms/LoadingIcon'
import { Formik, Form, Field } from 'formik'
import { CameraIcon } from '@heroicons/react/solid'
import { get, xor } from 'lodash'

import Input from 'components/atoms/Input'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import apiPost from 'lib/network/apiPost'
import apiPut from 'lib/network/apiPut'
import apiDelete from 'lib/network/apiDelete'
import countries from './modals/countries'

const CompanyProfile = ({ readOnly, loading, refetch, companyData, isEdit, id, roles }) => {
  const [previews, setPreviews] = useState({})
  const [files, setFiles] = useState({})
  const [profile, setProfile] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const history = useHistory()
  const profileRef = useRef(null)
  const user = useSelector(state => get(state, 'user.user'))

  useEffect(() => {
    setPreviews({
      profile: companyData?.companyProfile?.profile,
      logoUrl: companyData?.logoUrl,
      cover: companyData?.companyProfile?.cover,
    })
    setProfile({
      username: companyData?.companyProfile?.username,
      ...companyData?.companyProfile?.userKyc,
      twoFactorAuth: companyData?.companyProfile?.twoFactorAuth,
    })
  }, [companyData])

  const addUpdateCompany = async (values, { setSubmitting }) => {
    const data = {
      name: values.name,
      governorate: values.governorate,
      lat: values.lat,
      lon: values.lon,
      maxAge: values.maxAge,
      minAge: values.minAge,
      blockedCountries: values.blockedCountries,
    }
    delete data.id
    delete data.logoUrl

    setIsSubmitting(true)
    const formData = new FormData()

    if (files.cover) formData.append('cover', files.cover)
    if (files.profile) formData.append(`profile`, files.profile)
    if (files.logoUrl) formData.append(`logoUrl`, files.logoUrl)
    // eslint-disable-next-line
    Object.keys(data).forEach(key => {
      if (data[key] === 'null' || data[key] === undefined || data[key] === null) delete data[key]
      // eslint-disable-next-line
      if (typeof data[key] === 'string' || typeof data[key] === 'number' || typeof data[key] === 'boolean' || Array.isArray(data[key])) {
        formData.append(key, data[key])
      }
      // eslint-disable-next-line
      if (typeof data[key] === 'object' && !Array.isArray(data[key])) formData.append(key, JSON.stringify(data[key]))
    })
    // eslint-disable-next-line
    Object.keys(profile).forEach(key => {
      if (profile[key] === 'null' || profile[key] === undefined || profile[key] === null) delete profile[key]
      // eslint-disable-next-line
      if (typeof profile[key] === 'string' || typeof profile[key] === 'number' || typeof profile[key] === 'boolean' || Array.isArray(profile[key])) {
        formData.append(key, profile[key])
      }
    })

    try {
      if (isEdit) {
        const response = await apiPut(`company/edit/${id}`, formData)
        if (response.success) {
          notify.success('Company updated successfully!')
          history.push('/companies')
        }
      } else {
        const response = await apiPost('add/company', formData)
        if (response.success) {
          notify.success('Company added successfully!')
          history.push('/companies')
        }
      }
    } catch (error) {
      notify.error('Failed to save company')
    } finally {
      setIsSubmitting(false)
      setSubmitting(false)
    }
  }

  const deleteCompany = () => {
    notify.deleteConfirm({
      title: 'Delete company',
      message: 'Are you sure you want to delete this company? It will delete all the related data',
      onConfirm: async () => {
        const response = await apiDelete(`company/delete/${id}`)
        if (response?.success) {
          history.push('/companies')
          notify.success('Company deleted successfully')
        }
      },
    })
  }

  const showPreview = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreviews({ ...previews, [key]: preview })
  }

  const initialValues = isEdit
    ? companyData
    : {
        name: '',
        governorate: '',
        lat: '',
        lon: '',
        maxAge: '',
        minAge: '',
        blockedCountries: [],
      }

  return (
    <Formik initialValues={initialValues} onSubmit={addUpdateCompany} enableReinitialize>
      {({ values, setFieldValue, isValid }) =>
        !loading ? (
          <div className="w-full mb-[60px] flex flex-col">
            <div className="bg-white flex w-full">
              <Form className="space-y-8 py-8 w-full mx-auto px-8">
                <div>
                  <div className="border-b border-gray-200 pb-5 sm:pb-0">
                    <h3 className="text-xl font-medium leading-6 text-gray-900">Company Overview {readOnly ? '(Read-only)' : ''}</h3>
                    <div className="mt-3 sm:mt-4">
                      <div className="hidden sm:block"></div>
                    </div>
                  </div>
                  <div className="space-y-6 sm:space-y-5 pt-8">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:pt-5">
                      <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                        Company Logo
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="flex pb-4 items-center">
                          <div className="bg-gray relative h-[200px] w-[150px] border mr-4 border-dashed border-gray-300 rounded-md">
                            <input
                              id="logoUrl"
                              ref={profileRef}
                              name="logoUrl"
                              className="hidden"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={event => {
                                showPreview('logoUrl', event.target.files[0])
                                setFiles({ ...files, logoUrl: event.target.files[0] })
                              }}
                            />
                            {previews.logoUrl ? (
                              <span onClick={() => profileRef.current.click()}>
                                <img src={previews.logoUrl} alt="" className="cursor-pointer bg-cover  h-full w-[150px] rounded" />
                              </span>
                            ) : (
                              <div
                                onClick={() => profileRef.current.click()}
                                className="bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-gray-300 rounded-md h-full w-full cursor-pointer"
                              >
                                <CameraIcon className="text-gray-400 h-8 w-8" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:border-gray-200 sm:pt-5 mb-6">
                      <label htmlFor="name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Company name
                      </label>
                      <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                        <Field
                          type="text"
                          name="name"
                          as={Input}
                          placeholder="Company Name"
                          extraClassName="w-full"
                          id="name"
                          autoComplete="given-name"
                          className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                      <label htmlFor="governorate" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Governorate
                      </label>
                      <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                        <Field
                          type="text"
                          name="governorate"
                          as={Input}
                          id="governorate"
                          extraClassName="w-full"
                          className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                      <label htmlFor="lat" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Latitude
                      </label>
                      <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                        <Field
                          type="text"
                          name="lat"
                          as={Input}
                          placeholder=""
                          extraClassName="w-full"
                          id="lat"
                          className="block shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                      <label htmlFor="lon" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Longitude
                      </label>
                      <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                        <Field
                          type="text"
                          name="lon"
                          as={Input}
                          extraClassName="w-full"
                          id="lon"
                          className="block shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                      <label htmlFor="maxAge" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Max Age
                      </label>
                      <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                        <Field
                          type="text"
                          name="maxAge"
                          as={Input}
                          extraClassName="w-full"
                          id="maxAge"
                          className="block shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                      <label htmlFor="minAge" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Min Age
                      </label>
                      <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                        <Field
                          type="text"
                          name="minAge"
                          extraClassName="w-full"
                          as={Input}
                          id="minAge"
                          className="block shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                      <label htmlFor="blockedCountries" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Blocked Countries
                      </label>
                      <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                        <Selection
                          onChange={selectedValue => {
                            setFieldValue('blockedCountries', xor([selectedValue?.id, ...(values?.blockedCountries || [])]))
                          }}
                          title="Select Countries"
                          multiple={true}
                          list={countries}
                          value={values?.blockedCountries}
                          className="w-full"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {!readOnly && (
                  <div className="pt-2">
                    <div className="flex justify-between">
                      <button
                        type="submit"
                        disabled={!isValid || isSubmitting}
                        className={`mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 ${
                          (!isValid || isSubmitting) && 'opacity-50 cursor-not-allowed'
                        }`}
                      >
                        {isSubmitting ? (
                          <span className="flex items-start">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            <span>Please wait...</span>
                          </span>
                        ) : (
                          <span>{!isEdit ? 'Add Company' : 'Update'}</span>
                        )}
                      </button>
                      {isEdit && (
                        <div>
                          <button
                            type="button"
                            onClick={deleteCompany}
                            className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                          >
                            Delete Company
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        ) : (
          <div className="w-full h-[500px] flex items-center justify-center">
            <LoadingIcon />
          </div>
        )
      }
    </Formik>
  )
}

export default CompanyProfile
