import { useEffect, useState, useRef } from 'react'
import Layout from 'containers/Layout'
import moment from 'moment'
import { get } from 'lodash'
import apiGet from 'lib/network/apiGet'
import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import Button from 'components/atoms/Button'
import Popover from 'components/molecules/Popover'
import { accessibleOnClick } from 'utils'
import notify from 'components/atoms/Notify'
import { IoTrashBin, IoPencil, IoCheckmark, IoClose, IoSearch, IoCalendar } from 'react-icons/io5'

import useModal from 'hooks/useModal'
import Discount from './modals/DiscountModal'
import apiDelete from 'lib/network/apiDelete'
import { DateRangePicker } from 'react-date-range'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const Discounts = () => {
  const [filter, setFilter] = useState({ workspace: -1 })
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null })

  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [discounts, setDiscounts] = useState([])
  const [selectedDiscountId, setSelectedDiscountId] = useState(null)
  const [search, setSearch] = useState('')

  const [users, setUsers] = useState()
  const popover = useRef()

  const fetchDiscounts = async (page = 1) => {
    setLoading(true)

    const params = {}
    params.pageNumber = page
    params.pageSize = 20
    params.query = search || undefined
    params.startDate = dateRange.startDate ? dateRange.startDate.format('MM-DD-YYYY') : undefined
    params.endDate = dateRange.endDate ? dateRange.endDate.format('MM-DD-YYYY') : undefined
    const response = await apiGet('discounts', params)
    setDiscounts(page === 1 ? response.data.discount : [...discounts, ...response.data.discount])
    setPagination(response?.data?.pagination || {})
    setLoading(false)
  }

  useEffect(() => {
    fetchDiscounts()
  }, [search, dateRange])

  const deleteDiscount = id => {
    notify.deleteConfirm({
      title: 'Delete Coupon',
      message: 'Are you sure you want to delete this Discount Coupon? It will delete all related data.',
      onConfirm: async () => {
        const response = await apiDelete(`discount/${id}`)
        if (response?.success) {
          setDiscounts(prevDiscounts => prevDiscounts.filter(discount => discount._id !== id))
          notify.success('Discount Coupon deleted successfully')
        }
      },
    })
  }

  const handleAddDiscountSuccess = newDiscount => {
    fetchDiscounts()
    closeModal()
  }

  const [DiscountModal, openDiscountModal, closeModal] = useModal({
    content: <Discount onSuccess={handleAddDiscountSuccess} id={selectedDiscountId} />,
    title: 'Add Discount Coupens',
    dimensions: {
      height: '270px',
      width: '550px',
    },
  })

  const handleSelect = range => {
    setDateRange({
      startDate: moment(range.selection.startDate),
      endDate: moment(range.selection.endDate),
    })
  }

  const columns = [
    {
      name: 'Name',
      width: '10%',
      key: 'name',
      sortable: true,
      rendered: item => {
        return (
          <div className="ml-4">
            <div className="text-sm text-normal text-gray-900">{item?.cupon}</div>
          </div>
        )
      },
    },

    {
      width: '10%',
      name: 'Type',
      key: 'type',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item.type}</span>
            </div>
          </>
        )
      },
    },

    {
      width: '5%',
      name: 'Start Date',
      key: 'effectiveStartDate',
      sortable: true,
      rendered: item => {
        const createdTime = get(item, 'effectiveStartDate', '')
        const createdAt = createdTime ? moment(createdTime).format('MMMM DD, YYYY') : 'Registered'
        return createdAt
      },
    },
    {
      width: '5%',
      name: 'Expire Date',
      key: 'effectiveEndDate',
      sortable: true,
      rendered: item => {
        const createdTime = get(item, 'effectiveEndDate', '')
        const createdAt = createdTime ? moment(createdTime).format('MMMM DD, YYYY') : 'Registered'
        return createdAt
      },
    },

    {
      width: '10%',
      name: 'Amount',
      key: 'amount',
      sortable: true,
      rendered: item => {
        return item.type === 'Flat' ? (
          <div>
            <span className="text-[13px]">{item.amount}</span>
          </div>
        ) : null
      },
    },
    {
      width: '10%',
      name: 'Percentage',
      key: 'percentage',
      sortable: true,
      rendered: item => {
        return item.type === 'Percentage' ? (
          <div>
            <span className="text-[13px]">{item.percentage}</span>
          </div>
        ) : null
      },
    },
    {
      width: '10%',
      name: 'isActive',
      key: 'status',
      sortable: true,
      rendered: item => {
        return (
          <div className="flex items-center">
            <span className="text-[13px]">{item.status ? <IoCheckmark className="text-green-500" /> : <IoClose className="text-red-500" />}</span>
          </div>
        )
      },
    },

    {
      width: '5%',
      name: 'Created',
      key: 'created',
      sortable: true,
      rendered: item => {
        const createdTime = get(item, 'createdAt', '')
        const createdAt = createdTime ? moment(createdTime).fromNow() : 'Registered'
        return createdAt
      },
    },

    {
      width: '5%',
      name: 'Actions',
      key: 'actions',
      rendered: item => (
        <div className="flex space-x-4">
          <IoPencil
            className="text-dark-600  cursor-pointer"
            onClick={() => {
              setSelectedDiscountId(item._id)
              openDiscountModal()
            }}
          />
          <IoTrashBin className="text-red-500 cursor-pointer" onClick={() => deleteDiscount(item._id)} />
        </div>
      ),
    },
  ]

  return (
    <Layout
      headerRight={
        <div className="flex items-center  justify-end ">
          <div className="mt-1 relative rounded-md shadow-sm flex-grow">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
              <IoSearch className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
            </div>
            <input
              onChange={e => setSearch(e.target.value)}
              value={search}
              type="text"
              name="search"
              id="search"
              className="block  pl-9 shadow-none sm:text-sm bg-gray-100 border-0 focus:outline-none rounded-sm"
              placeholder="Search options"
            />
          </div>

          <div className="ml-4">
            <Popover
              custom
              placement="left"
              ref={popover}
              content={close => (
                <div className="p-4">
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: dateRange.startDate ? dateRange.startDate.toDate() : new Date(),
                        endDate: dateRange.endDate ? dateRange.endDate.toDate() : new Date(),
                        key: 'selection',
                      },
                    ]}
                    onChange={handleSelect}
                  />
                  <div className="flex justify-end mt-4">
                    <button
                      onClick={close}
                      className="w-[100px] py-2 mr-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 outline-none"
                    >
                      Close
                    </button>
                    <button
                      disabled={!dateRange.startDate && !dateRange.endDate}
                      onClick={() => {
                        setDateRange({ startDate: null, endDate: null })
                      }}
                      className="w-[100px] py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 outline-none"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              )}
            >
              <button
                type="button"
                className="inline-flex w-[250px] h-[30px] justify-center items-center border border-gray-300 shadow-sm text-sm leading-4 font-regular rounded-md text-gray-700 bg-white hover:bg-gray-50 outline-none"
              >
                <IoCalendar className="h-4 w-4 mr-4 text-gray-500" />
                {dateRange.startDate && dateRange.endDate ? `${dateRange.startDate.format('MM-DD-YYYY')} to ${dateRange.endDate.format('MM-DD-YYYY')}` : 'Select Date Range'}
              </button>
            </Popover>
          </div>

          <Button
            onClick={() => {
              setSelectedDiscountId(null)
              openDiscountModal()
            }}
          >
            Add Discounts
          </Button>
        </div>
      }
      title="Discounts"
    >
      <div className="flex flex-col">
        <div className="flex-1 flex items-stretch">
          <main className="flex-1">
            <div className="align-middle inline-block min-w-full">
              <div className="min-w-full">
                {!loading ? (
                  <>
                    {discounts?.length === 0 ? (
                      <EmptyState v2 description="No Discounts found" />
                    ) : (
                      <Table
                        hasMore={pagination?.currentPage !== pagination?.totalPages}
                        fetchData={() => fetchDiscounts(pagination?.currentPage + 1)}
                        sortBy={filter.sortBy}
                        sortOrder={filter.sortOrder}
                        handleSort={(sortBy, sortOrder) => {
                          setUsers([])
                        }}
                        data={discounts}
                        columns={columns}
                      />
                    )}
                  </>
                ) : (
                  <div className="w-full h-[200px] border-0 flex justify-center items-center">
                    <LoadingIcon />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
      <DiscountModal />
    </Layout>
  )
}

export default Discounts
