import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import UserProfile from 'pages/users/UserProfile'
import apiGet from 'lib/network/apiGet'
import { forEach } from 'lodash'
import useModal from 'hooks/useModal'
import PasswordRest from './modals/PasswordModal'

const AddUser = ({ readOnly, currentUserId }) => {
  const history = useHistory()

  const [isEdit, setIsEdit] = useState(null)
  const [userData, setUserData] = useState({})

  const [loading, setLoading] = useState(false)

  const id = useParams()?.id || currentUserId

  useEffect(() => {
    if (id) {
      setIsEdit(true)
      fetchUser(id)
    }
  }, [id])

  const fetchUser = async () => {
    setLoading(true)
    const response = await apiGet(`user/${id}`)

    forEach(Object.entries(response.data), ([key, value]) => {
      if (value === 'null') {
        delete response.data[key]
      }
    })

    const data = response.data

    setUserData(data)
    setLoading(false)
  }

  const [PasswordResetModal, openPasswordResetModal, closeModal] = useModal({
    content: <PasswordRest fetchUser={fetchUser} />,
    title: 'Update Password',
    dimensions: {
      height: '250px',
      width: '450px',
    },
  })

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <button
            onClick={() => {
              history.push('/users')
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Go Back
          </button>
          <button
            onClick={openPasswordResetModal}
            type="button"
            className="inline-flex text-white items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 bg-dark-600 font-medium rounded-md bg-white"
          >
            Password Reset
          </button>
        </div>
      }
      title={isEdit ? 'Edit User' : 'Add New User'}
      breadcrumbs={[
        { name: 'Users', pathname: '/users' },
        { name: isEdit ? 'User Details' : 'Add New Users', pathname: '/users/add', current: true },
      ]}
    >
      <div className="flex overflow-auto">
        {currentUserId ? (
          <UserProfile readOnly={readOnly} refetch={fetchUser} userData={userData} loading={loading} id={id} isEdit={isEdit} />
        ) : (
          <Switch>
            <Route key={id} path="/users/add" component={() => <UserProfile id={id} isEdit={isEdit} />} />
            <Route
              key={id}
              path="/users/edit/:id/user-profile"
              component={() => <UserProfile readOnly={readOnly} refetch={fetchUser} userData={userData} loading={loading} id={id} isEdit={isEdit} />}
            />
          </Switch>
        )}
      </div>
      <PasswordResetModal />
    </Layout>
  )
}

export default AddUser
