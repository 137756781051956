import { useEffect, useRef, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useHistory } from 'react-router-dom'
import { CameraIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import Input from 'components/atoms/Input'
import LoadingIcon from 'components/atoms/LoadingIcon'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import apiPut from 'lib/network/apiPut'
import apiDelete from 'lib/network/apiDelete'
import countries from 'pages/company/modals/countries'
import validations from 'validations'

const UserProfile = ({ readOnly, loading, userData, isEdit, id }) => {
  const [previews, setPreviews] = useState({})
  const [files, setFiles] = useState({})
  const [profile, setProfile] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [companyNames, setCompanyNames] = useState([])
  const history = useHistory()
  const profileRef = useRef(null)

  useEffect(() => {
    setPreviews({
      profile: userData?.userProfile?.profile,
      avatar: userData?.avatar,
      cover: userData?.userProfile?.cover,
    })
    setProfile({
      username: userData?.userProfile?.username,
      ...userData?.userProfile?.userKyc,
      twoFactorAuth: userData?.userProfile?.twoFactorAuth,
    })
  }, [userData])

  useEffect(() => {
    fetchCompanies()
  }, [])

  const fetchCompanies = async () => {
    const response = await apiGet('companies')

    if (response?.data?.company) {
      const companies = response.data?.company.map(company => ({
        id: company._id,
        name: company.name,
      }))
      setCompanyNames(companies)
    } else {
      setCompanyNames([])
    }
  }

  const addUpdateUser = async (values, { setSubmitting }) => {
    const data = {
      fullName: values.fullName,
      email: values.email,
      phone: values.phone,
      type: values.type,
      company: values.company,
      birthDate: values.birthDate,
      nationality: values.nationality,
      verified: values.verified,
      phoneVerified: values.phoneVerified,
      emailVerified: values.emailVerified,
      signUpComplete: values.signUpComplete,
      isDeleted: values.isDeleted,
      resident: values.resident,
    }

    delete data.id
    delete data.avatar

    if (data.type === 'admin' || data.type === 'user') {
      delete data.company
    }

    setIsSubmitting(true)
    const formData = new FormData()

    if (files.cover) formData.append('cover', files.cover)
    if (files.profile) formData.append('profile', files.profile)
    if (files.avatar) formData.append('avatar', files.avatar)

    // eslint-disable-next-line
    Object.keys(data).forEach(key => {
      if (data[key] === 'null' || data[key] === undefined || data[key] === null) delete data[key]
      // eslint-disable-next-line
      if (typeof data[key] === 'string' || typeof data[key] === 'number' || typeof data[key] === 'boolean' || Array.isArray(data[key])) {
        formData.append(key, data[key])
      }
      // eslint-disable-next-line
      if (typeof data[key] === 'object' && !Array.isArray(data[key])) formData.append(key, JSON.stringify(data[key]))
    })
    // eslint-disable-next-line
    Object.keys(profile).forEach(key => {
      if (profile[key] === 'null' || profile[key] === undefined || profile[key] === null) delete profile[key]
      // eslint-disable-next-line
      if (typeof profile[key] === 'string' || typeof profile[key] === 'number' || typeof profile[key] === 'boolean' || Array.isArray(profile[key])) {
        formData.append(key, profile[key])
      }
    })

    try {
      if (isEdit) {
        const response = await apiPut(`users/edit/${id}`, formData)
        if (response.success) {
          notify.success('User updated successfully!')
          history.push('/users')
        }
      } else {
        const response = await apiPost('user', formData)
        if (response.success) {
          notify.success('User added successfully!')
          history.push('/users')
        }
      }
    } catch (error) {
      notify.error('Failed to save user')
    } finally {
      setIsSubmitting(false)
      setSubmitting(false)
    }
  }

  const deleteUser = () => {
    notify.deleteConfirm({
      title: 'Delete user',
      message: 'Are you sure you want to delete this user? It will delete all the related data',
      onConfirm: async () => {
        const response = await apiDelete(`user/delete/${id}`)
        if (response?.success) {
          history.push('/users')
          notify.success('User deleted successfully')
        }
      },
    })
  }

  const showPreview = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreviews({ ...previews, [key]: preview })
  }

  const initialValues = isEdit
    ? userData
    : {
        roles: ['user'],
        verified: false,
        phoneVerified: false,
        emailVerified: false,
        signUpComplete: false,
        isDeleted: false,
        resident: false,
      }

  return (
    <Formik initialValues={initialValues} validationSchema={isEdit ? null : validations.AddUserSchema} onSubmit={addUpdateUser} enableReinitialize>
      {({ values, isValid, setFieldValue }) =>
        !loading ? (
          <div className="w-full mb-[60px] flex flex-col">
            <div className="bg-white flex w-full">
              <Form className="space-y-8 py-8 w-full mx-auto px-8">
                <div>
                  <div className="border-b border-gray-200 pb-5 sm:pb-0">
                    <h3 className="text-xl font-medium leading-6 text-gray-900">User Overview {readOnly ? '(Read-only)' : ''}</h3>
                  </div>

                  <div className="space-y-6 sm:space-y-5 pt-8">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:pt-5">
                      <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                        Avatar
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="flex pb-4 items-center">
                          <div className="bg-gray relative h-[200px] w-[150px]  border mr-4 border-dashed border-gray-300 rounded-md">
                            <input
                              id="avatar"
                              ref={profileRef}
                              name="avatar"
                              className="hidden"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={event => {
                                showPreview('avatar', event.target.files[0])
                                setFiles({ ...files, avatar: event.target.files[0] })
                              }}
                            />
                            {previews.avatar ? (
                              <span onClick={() => profileRef.current.click()}>
                                <img src={previews.avatar} alt="" className="cursor-pointer bg-cover  h-full w-[150px] rounded" />
                              </span>
                            ) : (
                              <div
                                onClick={() => profileRef.current.click()}
                                className="bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-gray-300 rounded-md h-full w-full cursor-pointer"
                              >
                                <CameraIcon className="text-gray-400 h-8 w-8" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <label htmlFor="fullName" className="block text-sm font-normal text-gray-700 mb-1">
                          Display name
                        </label>
                        <Field name="fullName" as={Input} type="text" className="w-full" />
                      </div>
                      <div>
                        <label htmlFor="email" className="block text-sm font-normal text-gray-700 mb-1">
                          Email address
                        </label>
                        <Field name="email" as={Input} type="text" className="w-full" />
                      </div>
                      <div>
                        <label htmlFor="phone" className="block text-sm font-normal text-gray-700 mb-1">
                          Phone number
                        </label>
                        <Field name="phone" as={Input} type="text" className="w-full" />
                      </div>
                      <div>
                        <label htmlFor="type" className="block text-sm font-normal text-gray-700 mb-1">
                          Type
                        </label>
                        <Selection
                          onChange={value => {
                            if (value?.id) {
                              setFieldValue('type', value.id)
                              if (value.id === 'admin' || value.id === 'user') {
                                setFieldValue('company', undefined)
                              }
                            }
                          }}
                          title="Select Types"
                          list={[
                            { id: 'company', name: 'company' },
                            { id: 'user', name: 'user' },
                            { id: 'admin', name: 'admin' },
                          ]}
                          value={values?.type || ''}
                          className="w-full"
                        />
                      </div>
                      {values?.type === 'company' && (
                        <div>
                          <label htmlFor="company" className="block text-sm font-normal text-gray-700 mb-1">
                            Company
                          </label>
                          <Selection
                            onChange={value => {
                              if (value?.id) {
                                setFieldValue('company', value.id)
                              }
                            }}
                            title="Select Companies"
                            list={companyNames}
                            value={values?.company || ''}
                            className="w-full"
                          />
                        </div>
                      )}
                      <div>
                        <label htmlFor="birthDate" className="block text-sm font-normal text-gray-700 mb-1">
                          DOB
                        </label>
                        <Field name="birthDate" as={Input} type="date" className="w-full" />
                      </div>
                      <div>
                        <label htmlFor="nationality" className="block text-sm font-normal text-gray-700 mb-1">
                          Nationality
                        </label>
                        <Selection
                          onChange={value => {
                            if (value && value.id) {
                              setFieldValue('nationality', value.id)
                            }
                          }}
                          title="Select Nationality"
                          list={countries}
                          value={values?.nationality || ''}
                          className="w-full"
                        />
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="grid grid-cols-4 gap-4 pt-5">
                        <div className="flex items-center space-x-4 pl-5">
                          <Field type="checkbox" name="verified" className="h-4 w-4 text-dark-600 border-gray-300 rounded focus:ring-dark-500" />
                          <label htmlFor="verified" className="block text-sm text-normal text-gray-700">
                            Verified
                          </label>
                        </div>

                        <div className="flex items-center space-x-4">
                          <Field type="checkbox" name="phoneVerified" className="h-4 w-4 text-dark-600 border-gray-300 rounded focus:ring-dark-500" />
                          <label htmlFor="phoneVerified" className="block text-sm text-normal text-gray-700">
                            Phone Verified
                          </label>
                        </div>
                        <div className="flex items-center space-x-4">
                          <input
                            type="checkbox"
                            name="active"
                            id="active"
                            checked={values?.active}
                            onChange={e => {
                              setFieldValue('active', e.target.checked)
                            }}
                            className="h-4 w-4 text-dark-600 border-gray-300 rounded focus:ring-dark-500"
                          />
                          <label htmlFor="active" className="block text-sm text-normal text-gray-700">
                            Active
                          </label>
                        </div>

                        <div className="flex items-center space-x-4 pr-5">
                          <Field type="checkbox" name="emailVerified" className="h-4 w-4 text-dark-600 border-gray-300 rounded focus:ring-dark-500" />
                          <label htmlFor="emailVerified" className="block text-sm text-normal text-gray-700">
                            Email Verified
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="grid grid-cols-4 gap-6 pt-5">
                        <div className="flex items-center space-x-4 pl-5">
                          <Field type="checkbox" name="signUpComplete" className="h-4 w-4 text-dark-600 border-gray-300 rounded focus:ring-dark-500" />
                          <label htmlFor="signUpComplete" className="block text-sm text-normal text-gray-700">
                            Sign Up Complete
                          </label>
                        </div>

                        <div className="flex items-center space-x-4">
                          <Field type="checkbox" name="isDeleted" className="h-4 w-4 text-dark-600 border-gray-300 rounded focus:ring-dark-500" />
                          <label htmlFor="isDeleted" className="block text-sm text-normal text-gray-700">
                            Is Deleted
                          </label>
                        </div>

                        <div className="flex items-center space-x-4 pr-5">
                          <Field type="checkbox" name="resident" className="h-4 w-4 text-dark-600 border-gray-300 rounded focus:ring-dark-500" />
                          <label htmlFor="resident" className="block text-sm text-normal text-gray-700">
                            Resident
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!readOnly && (
                  <div className="pt-2">
                    <div className="flex justify-between">
                      <button
                        type="submit"
                        disabled={!isValid || isSubmitting}
                        className={classNames(
                          (!isValid || isSubmitting) && 'cursor-not-allowed opacity-50',
                          'mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500'
                        )}
                      >
                        {isSubmitting ? (
                          <span className="flex items-start">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            <span>Please wait...</span>
                          </span>
                        ) : (
                          <span>{!isEdit ? 'Add' : 'Update'}</span>
                        )}
                      </button>

                      <div>
                        <button
                          type="button"
                          onClick={deleteUser}
                          className={classNames(
                            'mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500'
                          )}
                        >
                          Delete User
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        ) : (
          <div className="w-full h-[500px] flex items-center justify-center">
            <LoadingIcon />
          </div>
        )
      }
    </Formik>
  )
}

export default UserProfile
