import Input from 'components/atoms/Input'
import { Form } from 'formik'
import { upperFirst } from 'lodash'
import OtpTimer from 'components/atoms/OtpTimer'
import Selection from 'components/molecules/Selection'
import { useState, useEffect } from 'react'
import apiPost from 'lib/network/apiPost'
import notify from 'components/atoms/Notify'
import apiGet from 'lib/network/apiGet'

const CarAuth = ({ onSuccess, close }) => {
  const [status, setStatus] = useState('')
  const [marketSegment, setMarketSegment] = useState('')
  const [shifter, setShifter] = useState('')
  const [engineSize, setEngineSize] = useState('')
  const [insurance, setInsurance] = useState('')
  const [fuelPolicy, setFuelPolicy] = useState('')
  const [companyNames, setCompanyNames] = useState([])
  const [selectedCompany, setSelectedCompany] = useState('')
  const [modelYear, setModelYear] = useState('')

  const [formData, setFormData] = useState({
    depositAmount: '',
    dailyRate: '',
    weeklyRate: '',
    monthlyRate: '',
    deliveryCost: '',
    allowedKMPerDay: '',
    color: '',
    description: '',
    model: '',
    make: '',

    trim: '',
    numberOfDoors: '',
    numberOfSeats: '',
    engineSize: '',
  })

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleAddCar = async () => {
    const response = await apiPost('cars/add', {
      ...formData,
      fuelPolicy,
      insurance,
      status,
      modelYear,
      marketSegment,
      shifter,
      engineSize,
      company: selectedCompany,
    })
    if (response.success) {
      notify.success('car added successfully')
      onSuccess(response.data._id)
    } else {
      notify.error(response.message || 'Failed to add car')
    }
  }

  useEffect(() => {
    fetchCompanies()
  }, [])

  const fetchCompanies = async () => {
    const response = await apiGet('companies')

    if (response?.data) {
      const companies = response.data.company.map(company => ({
        id: company._id,
        name: company.name,
      }))
      setCompanyNames(companies)
    } else {
      setCompanyNames([])
    }
  }

  const years = Array.from({ length: 30 }, (v, k) => {
    const year = k + 1995
    return { id: year, name: year }
  })

  return (
    <div className="space-y-6 sm:space-y-5 ">
      <div className="grid grid-cols-3 gap-6">
        <div>
          <label htmlFor="modelYear" className="block text-sm font-normal text-gray-700 mb-1">
            Model Year
          </label>
          <Selection
            onChange={value => {
              if (value && value.id) {
                setModelYear(value.id)
              }
            }}
            title="Select Modal Year"
            list={years}
            value={modelYear}
            className="w-full"
          />
        </div>
        <div>
          <label htmlFor="depositAmount" className="block text-sm font-normal text-gray-700 mb-1">
            Deposit Amount
          </label>
          <input type="number" name="depositAmount" id="depositAmount" value={formData.depositAmount} onChange={handleChange} className="w-full" />
        </div>
        <div>
          <label htmlFor="dailyRate" className="block text-sm font-normal text-gray-700 mb-1">
            Daily Rate
          </label>
          <input type="number" name="dailyRate" id="dailyRate" value={formData.dailyRate} onChange={handleChange} className="w-full" />
        </div>
        <div>
          <label htmlFor="phone" className="block text-sm font-normal text-gray-700 mb-1">
            Weekly Rate
          </label>
          <input type="number" name="weeklyRate" id="weeklyRate" value={formData.weeklyRate} onChange={handleChange} className="w-full" />
        </div>
        <div>
          <label htmlFor="monthlyRate" className="block text-sm font-normal text-gray-700 mb-1">
            Monthly Rate
          </label>
          <input type="number" name="monthlyRate" id="monthlyRate" value={formData.monthlyRate} onChange={handleChange} className="w-full" />
        </div>
        <div>
          <label htmlFor="deliveryCost" className="block text-sm font-normal text-gray-700 mb-1">
            Delivery Cost
          </label>
          <input type="number" name="deliveryCost" id="deliveryCost" value={formData.deliveryCost} onChange={handleChange} className="w-full" />
        </div>
        <div>
          <label htmlFor="allowedKMPerDay" className="block text-sm font-normal text-gray-700 mb-1">
            Allowed KM Per Day
          </label>
          <input type="number" name="allowedKMPerDay" id="allowedKMPerDay" value={formData.allowedKMPerDay} onChange={handleChange} className="w-full" />
        </div>
        <div>
          <label htmlFor="fuelPolicy" className="block text-sm font-normal text-gray-700 mb-1">
            Fuel Policy
          </label>
          <Selection
            onChange={value => {
              if (value && value.id) {
                setFuelPolicy(value.id)
              }
            }}
            title="Select Fuel Policy"
            list={[
              { id: 'Free Tank', name: 'Free Tank' },
              { id: 'Like For Like', name: 'Like For Like' },
            ]}
            value={fuelPolicy}
            className="w-full"
          />
        </div>
        <div>
          <label htmlFor="insurance" className="block text-sm font-normal text-gray-700 mb-1">
            Insurance
          </label>
          <Selection
            onChange={value => {
              if (value && value.id) {
                setInsurance(value.id)
              }
            }}
            title="Select Insurance"
            list={[
              { id: '3rd Party Insurance', name: '3rd Party Insurance' },
              { id: 'Full Insurance', name: 'Full Insurance' },
            ]}
            value={insurance}
            className="w-full"
          />
        </div>
        <div>
          <label htmlFor="color" className="block text-sm font-normal text-gray-700 mb-1">
            Color
          </label>
          <input type="text" name="color" id="color" value={formData.color} onChange={handleChange} className="w-full" />
        </div>

        <div>
          <label htmlFor="status" className="block text-sm font-normal text-gray-700 mb-1">
            Status
          </label>
          <Selection
            onChange={value => {
              if (value && value.id) {
                setStatus(value.id)
              }
            }}
            title="Select Status"
            list={[
              { id: 'active', name: 'Active' },
              { id: 'booked', name: 'Booked' },
              { id: 'out of service', name: 'Out of Service' },
              { id: 'inProgress', name: 'In Progress' },
            ]}
            value={status}
            className="w-full"
          />
        </div>
        <div>
          <label htmlFor="fuelPolicy" className="block text-sm font-normal text-gray-700 mb-1">
            Company
          </label>
          <Selection
            onChange={value => {
              if (value && value.id) {
                setSelectedCompany(value.id)
              }
            }}
            title="Select Company"
            list={companyNames}
            value={selectedCompany}
            className="w-full"
          />
        </div>
        <div>
          <label htmlFor="fullName" className="block text-sm font-normal text-gray-700 mb-1">
            Model
          </label>
          <input type="text" name="model" id="model" value={formData.model} onChange={handleChange} className="w-full" />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-normal text-gray-700 mb-1">
            Make
          </label>
          <input type="text" name="make" id="insurance" value={formData.make} onChange={handleChange} className="w-full" />
        </div>

        <div>
          <label htmlFor="marketSegment" className="block text-sm font-normal text-gray-700 mb-1">
            Market Segment
          </label>
          <Selection
            onChange={value => {
              if (value && value.id) {
                setMarketSegment(value.id)
              }
            }}
            title="Select Market Segement"
            list={[
              { id: 'Economical', name: 'Economical' },
              { id: '4x4', name: '4x4' },
              { id: 'SUV', name: 'SUV' },
              { id: 'Sport', name: 'Sport' },
              { id: 'Sedan', name: 'Sedan' },
              { id: 'Luxury', name: 'Luxury' },
            ]}
            value={marketSegment}
            className="w-full"
          />
        </div>

        <div>
          <label htmlFor="shifter" className="block text-sm font-normal text-gray-700 mb-1">
            Shifter
          </label>
          <Selection
            onChange={value => {
              if (value && value.id) {
                setShifter(value.id)
              }
            }}
            title="Select Shifter"
            list={[
              { id: 'Manual', name: 'Manual' },
              { id: 'Automatic', name: 'Automatic' },
            ]}
            value={shifter}
            className="w-full"
          />
        </div>
        <div>
          <label htmlFor="trim" className="block text-sm font-normal text-gray-700 mb-1">
            Trim
          </label>
          <input type="text" name="trim" id="trim" value={formData.trim} onChange={handleChange} className="w-full" />
        </div>
        <div>
          <label htmlFor="numberOfDoors" className="block text-sm font-normal text-gray-700 mb-1">
            Number of Doors
          </label>
          <input type="number" name="numberOfDoors" id="numberOfDoors" value={formData.numberOfDoors} onChange={handleChange} className="w-full" />
        </div>
        <div>
          <label htmlFor="numberOfSeats" className="block text-sm font-normal text-gray-700 mb-1">
            Number of Seats
          </label>
          <input type="number" name="numberOfSeats" id="numberOfSeats" value={formData.numberOfSeats} onChange={handleChange} className="w-full" />
        </div>

        <div>
          <label htmlFor="engineSize" className="block text-sm font-normal text-gray-700 mb-1">
            Engine Size
          </label>
          <Selection
            onChange={value => {
              if (value && value.id) {
                setEngineSize(value.id)
              }
            }}
            title="Select Engine Size"
            list={[
              { id: 3, name: '3' },
              { id: 4, name: '4' },
              { id: 5, name: '5' },
              { id: 6, name: '6' },
              { id: 8, name: '8' },
            ]}
            value={engineSize}
            className="w-full"
          />
        </div>
      </div>
      <div>
        <label htmlFor="description" className="block text-sm font-normal text-gray-700 mb-1">
          Description
        </label>
        <textarea type="text" name="description" id="description" rows="5" value={formData.description} onChange={handleChange} className="w-full" />
      </div>

      <div className="mt-4 flex justify-end">
        <button
          type="button"
          className=" inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500  sm:ml-3"
          onClick={handleAddCar}
        >
          Add Car
        </button>
        <button
          type="button"
          className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none"
          onClick={() => close()}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default CarAuth
